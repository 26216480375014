import React from 'react';

import {
    Flash,
    Footer,
    Header,
    Navigation,
    Reveal,
    ScrollMarker,
    TrustIcons,
    Fixed,
} from '@brandedholdings/react-components';

import { siteMeta } from '../data/site_meta.yml';
import Helmet from 'react-helmet';
import "@brandedholdings/react-components/stylesheets/global.scss";

export default class DefaultPageWrapper extends React.Component {
    static propTypes = {

    };

    static defaultProps = {

    };

    focusPersonalLoanForm(e) {
        const formScrollMarker = 'heroStart';

        if (ScrollMarker.exists(formScrollMarker)) {
            e.preventDefault();
            ScrollMarker.scrollToMarker(formScrollMarker);
        }
    }

    render() {
        const { onClick, landingPage, } = this.props;
        const environment = siteMeta.environment;
        const navigationItems = [
            {
                heading: 'Home',
                href: '/',
                mobileSectionLeader: false
            },
            {
                heading: 'Contact',
                href: '/contact',
                mobileSectionLeader: false
            },
            {
                heading: 'How it Works',
                href: '/howitworks',
                mobileSectionLeader: false
            },
            {
                heading: 'FAQs',
                href: '/faq',
                mobileSectionLeader: false
            }
        ];

        const inlineNavigationItems = [
            {
                heading: 'About Us',
                href: '#about-us',
                mobileSectionLeader: false
            },
            {
                heading: 'How it Works',
                href: '#howitworks',
                mobileSectionLeader: false
            },
            {
                heading: 'FAQs',
                href: '#questions',
                mobileSectionLeader: false
            }
        ];

        const startedButton = () => (
            <div className="sticky-header__cta">
                <div className="button-set">
                    <a href="/" onClick={onClick} className="button button--warning button--tiny">
                        <span className="button--icon icon--padlock" />Get Started
                    </a>
                </div>
            </div>
        );
        return (
            <div>
                <Helmet
                    title={siteMeta.title}
                    meta={[
                        { name: 'description', content: siteMeta.description },
                        { name: 'format-detection', content: 'telephone-no' },
                        { name: 'viewport', content: 'width=device-width, initial-scale=1, minimum-scale=1, user-scalable=yes' }
                    ]}
                    link={[
                        { rel: 'manifest', href: siteMeta.url + '/manifest.json' }
                    ]}
                >
                    <html lang="en" />
                </Helmet>
                <div>
                    <ScrollMarker name="heroStart" />
                    <Fixed>
                        <Header siteMeta={siteMeta} hideSearch>
                            {landingPage && landingPage === 'true' ? (
                                <Reveal from="getStartedReveal">
                                    {startedButton()}
                                </Reveal>
                            ) : (
                                <>
                                    {startedButton()}
                                </>
                            )}
                            <Navigation useAnchor="true" sections={landingPage ? inlineNavigationItems : navigationItems} />
                        </Header>
                    </Fixed>
                    <div>
                        {['test', 'dev'].includes(environment) ? (
                            <Flash level="error" center>
                                <strong>Note:</strong> This is a {siteMeta.nameShort} testing environment.
                            </Flash>
                        ) : ''}
                        {this.props.children}
                        <Footer
                            useAnchor="true"
                            siteMeta={siteMeta}
                            trustIcons={<TrustIcons icons={['mcafee', 'secured']} height={90} siteMeta={siteMeta} />}
                            sidebar={false}
                            omitSitemap={true}
                            sections={[
                                {
                                    heading: 'Get a Loan',
                                    links: [
                                        {
                                            name: 'Get Started',
                                            href: '/form/',
                                        },
                                        {
                                            name: 'How it Works',
                                            href: '/howitworks/',
                                        },
                                        {
                                            name: 'FAQs',
                                            href: '/faq/',
                                        },
                                    ]
                                },
                                {
                                    heading: 'Support',
                                    links: [
                                        {
                                            name: 'Contact Us',
                                            href: '/contact/'
                                        },
                                        {
                                            name: 'Privacy Policy',
                                            href: '/privacy/'
                                        },
                                        {
                                            name: 'Terms of Use',
                                            href: '/terms/'
                                        },
                                        {
                                            name: 'Unsubscribe',
                                            href: '/unsubscribe/'
                                        }
                                    ]
                                }
                            ]}
                            disclaimer={
                                <div>
                                    <p><strong>APR Disclosure.</strong> Some states have laws limiting the Annual Percentage Rate (APR) that a lender can charge you. APRs for cash advance loans and for installment loans can and do vary, from lender to lender. Loans from a state that has no limiting laws or loans from a bank not governed by state laws may have an even higher APR. The APR is the rate at which your loan accrues interest and is based upon the amount, cost and term of your loan, repayment amounts and timing of payments. Lenders are legally required to show you the APR and other terms of your loan before you execute a loan agreement. APR rates are subject to change. Please review the information the lender provides carefully and make sure that you understand how much money you will receive and how much you are required to payback. </p>
                                    <p>
                                        THE OPERATOR OF THIS WEBSITE IS NOT A LENDER, does not broker loans to lending partners and does not make/fund any product offerings, loans or credit decisions. This Web Site does not constitute an offer or solicitation to lend. This site will securely submit the information provided to a lender. Providing your information on this Website does not guarantee approval for a product offering. {siteMeta.name} may change from time to time without notice. For details, questions or concerns regarding your application, please contact your lender directly. Lending partners will at their discretion perform credit checks with the three credit reporting bureaus: Experian, Equifax, TransUnion, or through alternative providers. The informational content contained on or linked to this site is for convenience only and {siteMeta.name} does not guarantee the accuracy of such information. Because all financial decisions must be fact dependent and based on your individual circumstances you should consult with a financial professional before making any financial decisions. {siteMeta.name} shall not be responsible for any financial actions taken by you in response to the content of this site.
                                        {' '}
                                        This service is not available in New York or to New York borrowers due to interest rate limits under New York law.
                                        {' '}
                                        WWMG,&nbsp;LLC. All rights reserved. Any unauthorized copying, distribution or dissemination of any kind is strictly prohibited. BILLSHAPPENUSA<sup>&reg;</sup> is an exclusive trademark of WWMG, LLC.
                                    </p>
                                </div>
                            }
                            excludeLegalLinks={true}
                        />
                    </div>

                </div>
            </div>);
    }
};
